import axios from 'axios'
import { Message } from 'element-ui'
import qs from 'qs'

axios.defaults.timeout = 5000 // 超时终止请求
axios.defaults.baseURL = process.env.VUE_APP_API_URL // 配置请求地址
// 设置请求拦截器
axios.interceptors.request.use(config => {
  // const token = getCookie('名称'); // 注意使用的时候需要引入cookie方法，推荐js-cookie
  // config.data = qs.stringify(config.data)
  // config.headers = {
  //   'Content-Type': 'application/x-www-form-urlencoded'
  // }
  // var istoken=localStorage.getItem('userinfo')
  // if(istoken) {

  //   config.headers['token'] = JSON.parse(istoken).token
  // }
  return config
},
error => {
  return Promise.reject(err)
}
)
// 设置响应拦截器
axios.interceptors.response.use(response => {
  const res = response.data // 取到响应返回的数据
  if (res.code == 200) {
    return res
  } else if (res.code == 603) {
    localStorage.removeItem('token')

    // location.href = url
    // Message.error(res.message);
  } else {
    // 状态码如果不是200则为报错，弹出报错信息，且不返回任何数据
    Message.error(res.msg)
  }
},
error => {
  Message.error(error.message)
  return Promise.reject(error)
}
)

export function myRequest (obj) {
  return new Promise((resolve, reject) => {
    var merged = obj.params

    switch (obj.method) {
      case 'post':
      case 'POST':  
        axios.post(obj.url, merged).then((res) => {
          resolve(res.data)
        }).catch((err) => {
          reject(err.data)
        })
        break
      case 'get':
      case "GET":
        axios.get(obj.url, { params: merged }).then((res) => {
          resolve(res.data)
        }).catch((err) => {
          reject(err.data)
        })
        break
    }
  })
}
export default myRequest
