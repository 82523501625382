 

const link={
    path: 'index',
    name: 'index',
    component: () => import('@/views/main.vue'),
    children:[
     
      {path:'/',redirect:'/index'},
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index.vue')
      },      
      {
        path: '/newslist',
        name: 'newslist',
        component: () => import('@/views/news/newslist.vue'),
     
      },  {
        path: '/newsinfo',
        name: 'newsinfo',
        component: () => import('@/views/news/newsinfo.vue'),
     
      },  
     
    ]
  }
export {link}