import Vue from 'vue'
import VueRouter from 'vue-router'

import {link} from './modules'
Vue.use(VueRouter)

var routes = [
  {path:'/',redirect:'/index'},
   
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
  },
 
  {
    path: '*',
    component: () => import('@/views/404.vue'),
  },
  
]
routes.push(link)
const router = new VueRouter({
 
  routes
})
router.beforeEach((to, from, next) => {
    
   next()
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
