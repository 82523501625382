import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state:{
    readMsgNum: 0,
  },
  getters:{
    readMsgNum: (state) => state.readMsgNum,
  },
  mutations:{
      setreadMsgNum(state,list){
         
        var num=list.filter(item=> item.state==0)
        if (num) {
          state.readMsgNum = num.length
        } else {
          state.readMsgNum=0
        }
        console.log(num)
      },
  }
})
export default store