<template>
  <div id="app">
 
    <router-view/>
	 
  </div>
</template>
<script>
 
export default {
	components: {
		 
	},
	data () {
		return {

		}
	},
	methods: {

	}
}
</script>
<style lang="scss">
$screenWidth:1200px;
$hoverbg:#cc8a18;
html,body{
	width: 100%;
}
*{
	margin: 0;
	padding: 0;
	color: #333;
	box-sizing: border-box;
}
.hand{
	cursor:pointer !important;
}


li{
	list-style: none;
}
a{
	text-decoration: none;
	position: relative;
}
input,textarea,select,button,a:focus {
     outline: none;
	 border: none;
	 box-shadow: 0px 0px 0px 0px;//去除阴影
}

.flex{
	display: flex;
	align-items: center;
}
.flex-around{
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.flex-between{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
// <=768px
@media (max-width: 768px){
	.container {
		width: 100%;
		margin: 15px auto;
	}
	.padding-top-bottom{
		padding:0px;
	}
}
// ≥768px
@media (min-width: 768px){
	.container {
		width: 750px;
		margin: 15px auto;
	}
}
// ≥992px
@media (min-width: 992px){
	.container {
		width: 970px;
		margin: 15px auto;
	}
	.padding-top-bottom{
		padding:10px 40px;
	}
}
// ≥1200px
@media (min-width: 1200px){
	.container {
		width: 1170px;
		margin: 15px auto;
	}
	.padding-top-bottom{
		padding:10px 80px;
	}
}
a:hover{
	color: #00A1E8;
}
a:hover:after {
	opacity: 1;
	transform: scalex(1);
}
a::after{
	background: #00A1E8;
	bottom: -2px;
	content: "";
	display: block;
	height: 1px;
	opacity: 0;
	position: absolute;
	transition: .3s;
	transform: scalex(0);
	transform-origin: center;
	width: 100%;
}

</style>
