import router from '../router/index'

const tourl = function (url, query={}) {
  router.push({ path: url, query })
  
  // console.log(url,query,router)
}

export {
  tourl
}
