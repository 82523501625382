import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import { tourl } from './utils/link.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css';
import VideoPlayer from 'vue-video-player'
import myRequest from './http/index.js'
// import VueQuillEditor from 'vue-quill-editor'
// import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import * as filters from '@/filters/index'
ElementUI.Dialog.props.closeOnClickModal.default = false
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.prototype.$myRequest = myRequest
Vue.prototype.tourl = tourl
Vue.use(VideoPlayer)
// Vue.use(VueQuillEditor)

Vue.use(ElementUI)

Vue.config.productionTip = false
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
document.title = '泖冠体育'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
