const sexshow = function (sex) {
  if (sex == 1) {
    return '男'
  } else if (sex == 0) {
    return '女'
  } else {
    return '未知'
  }
}
const wuellipsis = function (value, n) {
  if (!value) return ''
  if (value.length > n) {
    return value.slice(0, n) + '...'
  }
  return value
}
const orderStatues = function (value) {
  // 订单状态:0=全部,1=待确定,2=待发货,3=进行中,4=已完成，5=已取消
  var list=["全部","待确定","待发货","进行中","已完成","已取消"]
  if (!value) return '未知状态'
  if (value) {
    return list[value]
  }else{
    return '未知状态'
  }
   
}
export {
  sexshow,
  wuellipsis,
  orderStatues
}
